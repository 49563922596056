.info a {
  color: white;
  font-weight: bold;
  text-align: center;
}
.info a span{
  width: 100%;
  text-align: center;
  display: inline-block;
}
.info {
  margin: 0 auto;
  width: 885px;
}
.info h2 {
  text-align: center;
  margin-bottom: 40px;
}

.info2 a {
  color: white;
  font-weight: bold;
  text-align: center;
}
.info2 a span{
  text-align: center;
  display: inline-block;
}
.info2 {

  margin: 0 auto;
  padding-left: 4%;
  padding-right: 4%;
}
.info2 h2 {
  text-align: center;
  margin-bottom: 40px;
}

.player-wrapper {
  position: relative;
}

.colwidth {
  width: 85px;
}
.center {
  margin: auto;
  text-align: center;
}

.video {
  margin: auto;
}

.walswapbutton {
  background-color: #d28d97;
  margin: auto;
  margin-top: 3%;
  background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);
  color: #fff;
  border: 0;
  outline: 0;
  width:25%;
  font-size: 14px;
  padding: 6px 20px;
  border-radius: 20px;
  font-size: 1.6rem;
  font-weight: bold;
  text-align: center;
}

.limitwidth {
  max-width: 800px;
}

.bigwalswapbutton {
  background-color: #d28d97;
  margin: auto;
  margin-top: 3%;
  background: linear-gradient(to right, #f2709c 0%, #ff9472 100%);
  color: #fff;
  border: 0;
  outline: 0;
  width:20%;

  padding: 6px 20px;
  border-radius: 20px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}


@media only screen and (max-width: 900px) {
  /* For mobile phones:  */
  [class="bigwalswapbutton"] {
    width: 75%;
  }

  [class="walswapbutton"] {
    width: 55%;
  }

  [class="center"] {
    width: 50%;
  }
}